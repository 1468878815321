import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  getLoadManagementDashboardUserHouseFuseSelector,
  getLoadManagementDashboardChartDataSelector,
  yAxisMaxSelector,
  yAxisMinSelector
} from '../../../redux-store/selectors/loadManagementDashboard';

import LoadManagementBarChart from './LoadManagementBarChart';
import LoadManagementLineChart from './LoadManagementLineChart';

import '../index.scss';

const LoadManagementDashboardContainer = (props) => {
  const { zoomHandler, min, max } = props;

  const yAxisMin = useSelector(yAxisMinSelector);
  const yAxisMax = useSelector(yAxisMaxSelector);
  const chartData = useSelector(getLoadManagementDashboardChartDataSelector);
  const houseFuse = useSelector(getLoadManagementDashboardUserHouseFuseSelector);

  return (
    <div className="load-management-dashboard-container">
      <LoadManagementBarChart
        houseFuse={houseFuse}
        yAxisMax={yAxisMax}
        yAxisMin={yAxisMin}
      />
      <LoadManagementLineChart
        zoomHandler={zoomHandler}
        chartData={chartData}
        houseFuse={houseFuse}
        yAxisMax={yAxisMax}
        yAxisMin={yAxisMin}
        range={{ min, max }}
      />
    </div>
  );
};

LoadManagementDashboardContainer.propTypes = {
  zoomHandler: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default LoadManagementDashboardContainer;
