export const ONE_HOUR_MS = 3600000;
export const ONE_DAY_MS = 86400000;

export const tenSeconds = 10000;
export const fifteenMinutes = 900000;
export const days366 = 31622400000;
export const sixtyDays = 5184000000;
export const tenDays = 864000000;

export const hours25 = 90000000;

// TODO: find other way to get type of subscription
export const FREE_HELION_LICENSE_ID = process.env.REACT_APP_STAGES === 'production' ? '61f00e7c42131103259b4b1b' : '616591ef87809f2751decbf5';

export const DASHBOARD_COLORS = {
  TOTAL_SOC: '#dadada'
  // TODO: add other colors
};
