import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeHomeEnergyID,

  // R
  reqSMID: view.reqHomeEnergyID,

  // S
  SMID: view.HomeEnergyID,
  SMIDTolltip: view.HomeEnergyIDTolltip
};

export default replace;
