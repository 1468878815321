import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const LicenseTitle = styled('div', { shouldForwardProp: (prop) => prop !== 'selected' })(({ selected }) => ({
  fontSize: '19px',
  color: `${selected ? '#00925B' : 'rgba(0, 0, 0, .5)'}`,
  margin: '2px 0 4px 0',

  'label:hover &': {
    color: `${!selected ? 'rgba(0, 146, 91, 0.5)' : ''}`
  }
}));

const LicenseElement = (props) => {
  const { name, title, description = '', value, selected, disabled, needBR = true, callback } = props;

  const id = `radio_${name}_${value}`;
  return (
    <>
      <label htmlFor={id} className="customRadio">
        <LicenseTitle selected={selected}>
          <input
            type="radio"
            name={name}
            id={id}
            onChange={callback}
            value={value}
            checked={selected}
            disabled={disabled}
          />
          <div className={`checkmark ${disabled ? 'disabled' : ''}`} />
          {title}
        </LicenseTitle>
        <div className="license-description">{description}</div>
      </label>
      {needBR ? <br /> : null}
    </>
  );
};

LicenseElement.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  needBR: PropTypes.bool
};

export default LicenseElement;
